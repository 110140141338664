import { stage } from './stage'
import { cdn } from './cdn'

export const environment = {
  production: false,
  cognito :{
    userPoolId : "ca-central-1_Jz3bWft5o",
    userPoolWebClientId: "66ld9edn1faiha84h8fhvppjqs",
    region: 'ca-central-1',
    identityPoolId: 'ca-central-1:be54179d-7a98-482f-9e4f-53f647a90602',
    sessionToken: sessionStorage.getItem("sessionToken") || "",
    accessKeyId: sessionStorage.getItem("accessKeyId") || "",
    secretAccessKey: sessionStorage.getItem("secretAccessKey") || "",
  },
  sns: {
    url: "",
  },
  api:{
    cdn: 'https://i8obulpck5.execute-api.ca-central-1.amazonaws.com/nova-qas',
    stage: stage,
    route: {
        createUser: "this is qas",
        updateUser: "",
        authenticate: "/authenticate",
        redirectLink: 'https://qas.profilnova.com', // was 'dev.profilnova.com',
        authenticateUlockUser: '',
        getUsers: "/users",
        deleteUsers: "/deleteUsers",
        changeUserLocation: "/changeUserLocation",
        getSelectedUsers: "/getSelectedUsers"

    }
  },
  users:{

  },
  saml:{
    microsoft: {
     muirwood:{
      clientId: '8357310a-1762-4aa1-9706-90fe131add9f',
      authority: 'https://login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/',
      provider: 'login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/v2.0',
      // redirectUri: 'http://localhost:4200/login',
      redirectUri: 'https://muirwood.profilnova.com/login',

      navigateToLoginRequestUrl: true,
      loginFailedRoute: '/login',
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      scopes: ['user.read', 'openid']
     },
     nova:{
      clientId: '8357310a-1762-4aa1-9706-90fe131add9f',
      authority: 'https://login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/',
      provider: 'login.microsoftonline.com/c0b50357-f018-4f60-bf08-47342010bf9b/v2.0',
      redirectUri: 'http://localhost:4200/login',
      navigateToLoginRequestUrl: true,
      loginFailedRoute: '/login',
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
      scopes: ['user.read', 'openid']
     },

    }
  }
}
